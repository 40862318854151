html,
body,
#root {
	height: 100%
}

.div_stu_container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #F3F3F3;
}

.ant-advanced-search-form {
	display: flex;
	flex-direction: row;
	background-color: white;
	margin: 15px;
	padding: 10px 20px;
	align-items: center;
	border: 2px solid #f3f3f3;
	box-shadow: 2px 2px 4px #9999998C;
	border-radius: 5px;
}

.ant-advanced-search-form-item {
	margin: 15px;
}

.student_btn {
	width: 100px;
}

.div_table {
	flex: 1;
	background-color: white;
	margin: 5px 15px 15px 15px;
	padding: 15px;
	border: 2px solid #f3f3f3;
	box-shadow: 2px 2px 4px #9999998C;
	border-radius: 5px;
}

.div_student_info {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #d8d6d6;
	padding: 7px 0px;
}