html,
body,
#root {
	height: 100%
}

.div_container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.div_title {
	font-size: 26px;
	color: #333333;
	font-weight: bold;

}

.div_form {
	padding: 40px 70px 40px 40px;
	border: 2px solid #f3f3f3;
	box-shadow: 2px 2px 4px #9999998C;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.input {
	width: 200px;
}

.submit {
	width: 200px;
}