html,
body,
#root {
  height: 100%
}

.div_add_stu_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 15px;
}

.div_add_submit {
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
}